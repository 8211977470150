import { render, staticRenderFns } from "./TableHomework.vue?vue&type=template&id=4205b79d&scoped=true&"
import script from "./TableHomework.vue?vue&type=script&lang=js&"
export * from "./TableHomework.vue?vue&type=script&lang=js&"
import style0 from "./TableHomework.vue?vue&type=style&index=0&id=4205b79d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4205b79d",
  null
  
)

export default component.exports